exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-approach-index-js": () => import("./../../../src/pages/approach/index.js" /* webpackChunkName: "component---src-pages-approach-index-js" */),
  "component---src-pages-blog-apprenticeships-worth-it-js": () => import("./../../../src/pages/blog/apprenticeships-worth-it.js" /* webpackChunkName: "component---src-pages-blog-apprenticeships-worth-it-js" */),
  "component---src-pages-blog-commercial-intelligence-system-case-study-js": () => import("./../../../src/pages/blog/commercial-intelligence-system-case-study.js" /* webpackChunkName: "component---src-pages-blog-commercial-intelligence-system-case-study-js" */),
  "component---src-pages-blog-devops-have-we-left-something-behind-js": () => import("./../../../src/pages/blog/devops-have-we-left-something-behind.js" /* webpackChunkName: "component---src-pages-blog-devops-have-we-left-something-behind-js" */),
  "component---src-pages-blog-guide-to-design-thinking-js": () => import("./../../../src/pages/blog/guide-to-design-thinking.js" /* webpackChunkName: "component---src-pages-blog-guide-to-design-thinking-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-usable-over-viable-js": () => import("./../../../src/pages/blog/usable-over-viable.js" /* webpackChunkName: "component---src-pages-blog-usable-over-viable-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thankyou-js": () => import("./../../../src/pages/contact/thankyou.js" /* webpackChunkName: "component---src-pages-contact-thankyou-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-design-js": () => import("./../../../src/pages/product-design.js" /* webpackChunkName: "component---src-pages-product-design-js" */),
  "component---src-pages-subscribe-index-js": () => import("./../../../src/pages/subscribe/index.js" /* webpackChunkName: "component---src-pages-subscribe-index-js" */),
  "component---src-pages-subscribe-thankyou-js": () => import("./../../../src/pages/subscribe/thankyou.js" /* webpackChunkName: "component---src-pages-subscribe-thankyou-js" */),
  "component---src-pages-thankyou-index-js": () => import("./../../../src/pages/thankyou/index.js" /* webpackChunkName: "component---src-pages-thankyou-index-js" */),
  "component---src-pages-webinar-index-js": () => import("./../../../src/pages/webinar/index.js" /* webpackChunkName: "component---src-pages-webinar-index-js" */),
  "component---src-pages-webinar-thankyou-js": () => import("./../../../src/pages/webinar/thankyou.js" /* webpackChunkName: "component---src-pages-webinar-thankyou-js" */)
}

